import SimpleBar from 'simplebar-react';
// @mui
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const StyledRootScrollbar = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));

export const StyledScrollbar = styled(SimpleBar)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      background: 'transparent',
    },
    '&.simplebar-visible:before': {
      opacity: 0,
    },
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent' /* You can set a background color here if needed */,
  },
  '&::-webkit-scrollbar': {
    width: '0.5em',
  },
}));
